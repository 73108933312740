import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Button, Divider, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import PATHS from 'lib/navigation/paths'
import ArticlesList from 'views/general/templates/ArticleCategory/components/ArticlesList'
import { getArticles } from '../../ArticleList/helpers'

const StyledP = styled(P)`
  max-width: 480px;
`

const StyledGrid = styled(Grid)`
  align-items: center;
`

const SEARCH_RESULTS_LIMIT = 9

const SearchResults = ({
  query,
  results,
  allResults,
}: {
  query: string
  results: GatsbyTypes.ContentfulArticle[]
  allResults?: GatsbyTypes.ContentfulArticle[]
}): React.ReactElement => {
  const [showResultsAmount, setShowResultsAmount] =
    useState<number>(SEARCH_RESULTS_LIMIT)
  const [showButton, setShowButton] = useState<boolean>(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const resultsRef = useRef<HTMLDivElement>(null)

  const articleBlocks = getArticles(query ? results : allResults)
  const articleBlocksAmount = articleBlocks.length

  const handleShowButton = () =>
    setShowButton(articleBlocksAmount >= showResultsAmount)

  useEffect(() => handleShowButton(), [])

  useEffect(() => handleShowButton(), [articleBlocks, showResultsAmount])

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    } else {
      const firstNewlyShownResult = resultsRef.current?.firstElementChild
        ?.childNodes[showResultsAmount - SEARCH_RESULTS_LIMIT]
        ?.firstChild as HTMLLinkElement
      if (firstNewlyShownResult) firstNewlyShownResult.focus()
    }
  }, [showResultsAmount])

  return (
    <div ref={resultsRef}>
      {articleBlocksAmount > 0 ? (
        <ArticlesList articles={articleBlocks} amount={showResultsAmount} />
      ) : (
        <StyledGrid
          container
          containerPaddingTop={0}
          containerPaddingBottom={0}
        >
          <Grid.Item span={7} spanFromXL={8}>
            <H tag="h3" size="M">
              Unfortunately, there are no results for “{query}”.
            </H>
            <StyledP size="M">
              We recommend you search for a different phrase. Or you can click
              the button below to see all of our guides.
            </StyledP>
            <Divider margin={['L', 0]} />

            <Button.Primary tag={Link} to={PATHS.GENERAL.CONTENT_HUB}>
              See all guides
            </Button.Primary>
          </Grid.Item>
          <Grid.Item span={5} spanFromXL={4}>
            <Image
              path="illustrations/blob-bird-binoculars"
              width={500}
              stretch
            />
          </Grid.Item>
        </StyledGrid>
      )}
      {showButton && (
        <Wrapper centered>
          <Button.Primary
            onClick={() =>
              setShowResultsAmount(
                (prevValue) => prevValue + SEARCH_RESULTS_LIMIT
              )
            }
          >
            Load more results
          </Button.Primary>
        </Wrapper>
      )}
    </div>
  )
}

export default SearchResults
