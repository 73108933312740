import React from 'react'
import styled from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

const StyledLoaderSpinner = styled.div<{
  centered?: boolean
  color?: string
  height?: string
  size?: string
}>`
  display: inline-block;
  position: relative;
  width: ${({ centered }) => (centered ? '100%' : '100vw')};
  height: ${({ height }) => height || 'calc(100vh - 95px)'};

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid ${({ color }) => color || COLOR.ACCENT.PRIMARY};
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -1s;
    }
  }

  @keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      width: ${({ size }) => size};
      height: ${({ size }) => size};
      opacity: 0;
    }
  }
`

interface LoaderSpinnerProps {
  centered?: boolean
  color?: string
  height?: string
  size?: string
}

const LoaderSpinner = ({
  centered,
  color,
  height,
  size = '120px',
}: LoaderSpinnerProps): React.ReactElement => (
  <>
    <StyledLoaderSpinner
      color={color}
      height={height}
      size={size}
      centered={centered}
    >
      <div />
      <div />
    </StyledLoaderSpinner>
  </>
)

export default LoaderSpinner
