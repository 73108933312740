import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Button, H, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { smallCaps } from '@farewill/ui/helpers/text'
import PATHS from 'paths'
import YellowBanner from 'components/YellowBanner'
import { MIN_BANNER_HEIGHT } from '../constants'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: ${MIN_BANNER_HEIGHT.LARGE_SCREENS};

  padding-bottom: ${GTR.XXL};

  ${screenMax.s`
    min-height: ${MIN_BANNER_HEIGHT.SMALL_SCREENS};
  `}
`

const StyledButton = styled(Button.Plain)`
  color: ${COLOR.BLACK};
  ${smallCaps}
`

const SearchBanner = ({ query }: { query?: string }): React.ReactElement => (
  <YellowBanner textureStyle="A" centered={false}>
    <StyledWrapper>
      <StyledButton back flush tag={Link} to={PATHS.GENERAL.CONTENT_HUB}>
        BACK
      </StyledButton>

      <H tag="h1" size="XL" decorative>
        {query ? `Search results for “${query}”` : `All guides`}
      </H>
    </StyledWrapper>
  </YellowBanner>
)

export default SearchBanner
