import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { BORDER_RADIUS, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'

const BORDER_HEIGHT = GTR.XXS

const StyledBorderWrapper = styled(Wrapper)<{ $hasContainer: boolean }>`
  ${({ $hasContainer }) =>
    !$hasContainer &&
    `
  display: flex;
  justify-content: space-between;
`}
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
`

const StyledNavigationList = styled.ul`
  display: flex;
  margin: 0;
`

const StyledNavigationItem = styled.li`
  &:not(:last-of-type) {
    margin-right: ${GTR.L};
  }

  line-height: 1;
`

const StyledButton = styled.button<{ isActive: boolean }>`
  text-decoration: none;
  border-bottom: solid ${BORDER_HEIGHT} transparent;
  position: relative;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    text-decoration: none;
  }

  ${({ isActive }) =>
    !isActive &&
    `
      &:hover {
        border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.15)};
      }

      &:active {
        border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.25)};
      }

      &:focus {
        border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.35)};
      }
    `}

  // increase touch area
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: calc(-1 * ${BORDER_HEIGHT});
    left: calc(-1 * ${GTR.XS});
    right: calc(-1 * ${GTR.XS});
  }

  border-bottom-color: ${({ isActive }) =>
    isActive ? COLOR.ACCENT.SECONDARY : 'none'};
`

const StyledNavigationLink = styled(StyledButton)<{
  button?: boolean
}>`
  display: block;
  height: 100%;
  padding-top: ${GTR.M};
  padding-bottom: ${GTR.S};
  color: ${COLOR.BLACK};

  ${({ isActive }) =>
    isActive &&
    `
    font-weight: ${FONT.WEIGHT.BOLD};
  `}

  ${({ button }) =>
    button &&
    `
    height: unset;
    margin-top: ${GTR.S};
    padding: ${GTR.XS} ${GTR.S} ${GTR.XXS};
    width: calc(2 * ${GTR.XXL});
    text-align: center;
    background-color: ${COLOR.ACCENT.SECONDARY};
    border-radius: ${BORDER_RADIUS.S};
    color: ${COLOR.WHITE};
    font-weight: ${FONT.WEIGHT.BOLD};
  `}
`

const StyledContent = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => !isVisible && 'display: none'};
`

const getChildrenLabels = (items: JSX.Element[]): string[] =>
  items.reduce((list: string[], item) => [...list, item.props.label], [])

interface TabsItemProps {
  children: JSX.Element[] | JSX.Element
  label?: string
}

const TabsItem = ({
  children,
  ...props
}: TabsItemProps): React.ReactElement => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Wrapper {...props}>{children}</Wrapper>
)

interface TabsNavProps {
  labels: string[]
  container?: boolean
  activeTabIndex: number
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>
}

const TabsNav = ({
  labels,
  container,
  activeTabIndex,
  setActiveTabIndex,
}: TabsNavProps): React.ReactElement => (
  <StyledBorderWrapper margin={[0, 0, 'L']} $hasContainer={container}>
    <Wrapper
      container={container}
      containerPaddingBottom={0}
      containerPaddingTop={0}
    >
      <StyledNavigationList>
        {labels.map((label, i) => (
          <StyledNavigationItem key={label}>
            <StyledNavigationLink
              isActive={activeTabIndex === i}
              onClick={() => setActiveTabIndex(i)}
            >
              {label}
            </StyledNavigationLink>
          </StyledNavigationItem>
        ))}
      </StyledNavigationList>
    </Wrapper>
  </StyledBorderWrapper>
)

const Tabs: React.FC<{
  children: JSX.Element[]
  container?: boolean
  activeIndex?: number
}> & {
  Item: React.FC<TabsItemProps>
} = ({ children, container, activeIndex }) => {
  const labels = getChildrenLabels(children)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeIndex || 0)

  useEffect(() => {
    setActiveTabIndex(activeIndex || 0)
  }, [activeIndex])

  return (
    <>
      <TabsNav
        labels={labels}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        container={container}
      />
      <div>
        {children &&
          children.map((child: JSX.Element, i: number) => (
            <StyledContent
              isVisible={activeTabIndex === i}
              key={`tabs-nav-${child.props.label}`}
            >
              {child}
            </StyledContent>
          ))}
      </div>
    </>
  )
}

Tabs.Item = TabsItem

export default Tabs
