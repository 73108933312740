/**
 * This helper is used both in lib/gatsby/node to create blocks
 * for articles in Content Hub, and on client side for Articles
 * in Articles section (which might be removed at some point).
 *
 * @param {*} articles - all articles to group
 * @param {*} isSSR - if used in lib/gatsby, set to true
 */

export const getArticles = (articles, isSSR = false) => {
  const usedIds = []
  const articlesSorted = []
  articles.forEach((article, index) => {
    if (usedIds.includes(article.id)) return null
    const isArticleWithImage = isSSR ? !!article.image___NODE : !!article.image

    if (!isArticleWithImage && articles[index + 1]) {
      articlesSorted.push([articles[index], articles[index + 1]])
      usedIds.push(articles[index].id, articles[index + 1].id)
    } else {
      articlesSorted.push([articles[index]])
      usedIds.push(articles[index].id)
    }

    return null
  })

  return articlesSorted
}
