import React from 'react'
import styled from 'styled-components'
import { Grid } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { screenMax } from '@farewill/ui/helpers/responsive'
import ArticleCard from 'views/general/public/ContentHub/components/SingleArticle'
import { IMAGE_POSITION } from 'views/general/public/ContentHub/types'

const StyledGridItem = styled(Grid.Item)`
  ${({ $isGroup }) =>
    $isGroup &&
    `
    display: grid;
    grid-template-rows: auto auto;
  `}

  ${screenMax.m`
    margin-bottom: ${GTR.S};
  `}
`

const SingleArticle = ({
  article,
  noImage = false,
  isLarge = false,
}: {
  article: GatsbyTypes.ContentfulArticle
  noImage?: boolean
  isLarge?: boolean
}) => {
  let imagePosition: GatsbyTypes.Maybe<IMAGE_POSITION>
  if (!noImage) {
    imagePosition = isLarge ? IMAGE_POSITION.RIGHT : IMAGE_POSITION.BOTTOM
  }
  return <ArticleCard data={article} imagePosition={imagePosition} />
}

const ArticlesGroup = ({
  articles,
}: {
  articles: GatsbyTypes.ContentfulArticle[]
}) => (
  <>
    {articles.map((article) => (
      <SingleArticle article={article} key={article.contentful_id} noImage />
    ))}
  </>
)

const ArticlesList = ({
  articles,
  amount,
  hasLargeArticle,
}: {
  articles: GatsbyTypes.ContentfulArticle[][]
  amount?: number
  hasLargeArticle?: boolean
}): React.ReactElement => {
  const articlesToDisplay = amount ? articles.splice(0, amount) : articles
  return (
    <Grid container containerPaddingTop={0}>
      {articlesToDisplay.map((articlesGroup, index) => {
        const isLarge =
          hasLargeArticle && index === 0 && articlesGroup.length === 1
        return (
          <StyledGridItem
            key={articlesGroup[0].contentful_id}
            span={12}
            spanFromM={isLarge ? 12 : 6}
            spanFromL={isLarge ? 12 : 4}
            $isGroup={articlesGroup.length > 1}
          >
            {articlesGroup.length > 1 ? (
              <ArticlesGroup articles={articlesGroup} />
            ) : (
              <SingleArticle article={articlesGroup[0]} isLarge={isLarge} />
            )}
          </StyledGridItem>
        )
      })}
    </Grid>
  )
}

export default ArticlesList
