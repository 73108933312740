import React, { useEffect, useState } from 'react'
import { graphql, navigate, PageProps } from 'gatsby'
import qs from 'qs'
import { useFlexSearch } from 'react-use-flexsearch'
import { Wrapper, Grid } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import { PRODUCTS } from 'lib/products/constants'
import { getQueryParameters } from 'lib/url/getQueryParameters'
import DefaultLayout from 'layouts/DefaultLayout'
import Footer from 'views/general/templates/components/Footer'
import { CONTENT_HUB_SEARCH_OPTIONS } from 'views/general/public/ContentHub/constants'
import PATHS from 'paths'

import JSONLD from 'components/JSONLD'
import Tabs from 'components/Tabs'
import { CTA_TYPES } from 'components/Cta/constants'
import LoaderSpinner from 'components/LoaderSpinner'
import { TRUSTMARKS } from 'components/AccoladesProduct/constants'
import { normalizeArray } from 'lib/graphql/normalize'
import SearchBanner from '../components/SearchBanner'
import SearchResults from '../components/SearchResults'
import SearchBox from '../components/SearchBox'

const GENERAL_TAB_INDEX = 0
const COMPANY_TAB_INDEX = 1

interface SearchPageProps
  // eslint-disable-next-line @typescript-eslint/ban-types
  extends PageProps<object, object, { scrollY: number; scrollX: number }> {
  data: {
    allContentfulArticle: {
      edges: { node: GatsbyTypes.ContentfulArticle }[]
    }
    localSearchGeneralArticles: GatsbyTypes.LocalSearchGeneralArticles
    localSearchCompanyArticles: GatsbyTypes.LocalSearchCompanyArticles
  }
}

const SearchPage = ({
  location,
  data,
}: SearchPageProps): React.ReactElement => {
  const params = getQueryParameters()
  const [query, setQuery] = useState(params.query as string)
  const [newQuery, setNewQuery] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [activeIndex, setActiveIndex] = useState(0)
  const [initialLoad, setInitialLoad] = useState(true)
  const companyResults = useFlexSearch(
    query,
    data?.localSearchCompanyArticles.index,
    data?.localSearchCompanyArticles.store
  )
  const generalResults = useFlexSearch(
    query,
    data?.localSearchGeneralArticles.index,
    data?.localSearchGeneralArticles.store
  )

  let allGeneralResults: GatsbyTypes.ContentfulArticle[] = []
  let allCompanyResults: GatsbyTypes.ContentfulArticle[] = []

  if (!params.query) {
    allGeneralResults = normalizeArray<GatsbyTypes.ContentfulArticle>(
      data.allContentfulArticle
    ).filter(
      (article) =>
        article.articleCategories === null ||
        !!article.articleCategories?.find(
          (categories) =>
            categories?.contentful_id &&
            CONTENT_HUB_SEARCH_OPTIONS.COMPANY_CATEGORIES.includes(
              categories?.contentful_id
            )
        )
    )

    allCompanyResults = normalizeArray<GatsbyTypes.ContentfulArticle>(
      data.allContentfulArticle
    ).filter(
      (article) =>
        !!article.articleCategories?.find(
          (categories) =>
            categories?.contentful_id &&
            CONTENT_HUB_SEARCH_OPTIONS.COMPANY_CATEGORIES.includes(
              categories?.contentful_id
            )
        )
    )
  }

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
    } else {
      const hasCompanyResults = companyResults.length > 0
      const hasAllGeneralResults = !query && allGeneralResults.length > 0
      const hasGeneralResults = query && generalResults.length > 0

      setActiveIndex(
        hasGeneralResults || hasAllGeneralResults || !hasCompanyResults
          ? GENERAL_TAB_INDEX
          : COMPANY_TAB_INDEX
      )

      setIsLoading(false)
    }
  }, [companyResults, generalResults])

  const updateUrl = (): void => {
    setQuery(newQuery)
    navigate(
      `${PATHS.GENERAL.CONTENT_HUB_SEARCH}?${qs.stringify({
        query: newQuery,
      })}`,
      {
        state: { scrollX: window.scrollX, scrollY: window.scrollY },
      }
    )
  }

  const handleKeydown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') updateUrl()
  }

  useEffect(() => {
    if (location?.state?.scrollY >= 0 && location.state.scrollX >= 0) {
      window.scrollTo({
        top: location.state.scrollY,
        left: location.state.scrollX,
      })
    }
  }, [location])

  return (
    <>
      <JSONLD data={FAREWILL_JSONLD} />
      <DefaultLayout title="Content Hub" description="Content Hub">
        <Wrapper background={COLOR.WHITE}>
          <SearchBanner query={query} />
          <Wrapper padding={['M', 0, 'L']}>
            <Grid container containerPaddingTop={0} containerPaddingBottom="S">
              <Grid.Item
                spanFromM={5}
                startColumnFromM={8}
                spanFromL={4}
                startColumnFromL={9}
              >
                <SearchBox
                  onKeyDown={handleKeydown}
                  onChange={(event) => setNewQuery(event.target.value)}
                  onButtonClick={() => updateUrl()}
                />
              </Grid.Item>
            </Grid>

            {isLoading ? (
              <LoaderSpinner height="300px" />
            ) : (
              <Tabs container activeIndex={activeIndex}>
                <Tabs.Item
                  label={`General (${
                    query ? generalResults.length : allGeneralResults.length
                  })`}
                >
                  <SearchResults
                    query={query}
                    results={generalResults}
                    allResults={allGeneralResults}
                  />
                </Tabs.Item>
                <Tabs.Item
                  label={`Company news (${
                    query ? companyResults.length : allCompanyResults.length
                  })`}
                >
                  <SearchResults
                    query={query}
                    results={companyResults}
                    allResults={allCompanyResults}
                  />
                </Tabs.Item>
              </Tabs>
            )}
          </Wrapper>
          <Footer
            title="Changing the way the world deals with death"
            description="At Farewill we're making everything to do with death easier, friendlier and more affordable. We provide probate, wills and funerals with a difference, and so far we've helped over 60,000 families in the UK. "
            cta={{
              type: CTA_TYPES.LINK,
              text: 'Find out more',
              link: '/',
            }}
            trustmarks={TRUSTMARKS.PROBATE}
            product={PRODUCTS.PROBATE}
            smallText
          />
        </Wrapper>
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  query ContentHubSearch {
    allContentfulArticle {
      edges {
        node {
          contentful_id
          id
          type
          title
          description {
            description
          }
          articleCategories {
            contentful_id
          }
          image {
            file {
              url
            }
          }
          slug
        }
      }
    }
    localSearchCompanyArticles {
      index
      store
    }
    localSearchGeneralArticles {
      index
      store
    }
  }
`

export default SearchPage
